@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;500;600;700&display=swap");

:root {
    --button_font: 16px;
    --table_data_font: 14px;
    --table_header_font: 15px;
    --primary_heading_font: 20px;
    --side_nav_font: 16px;
    --font_regular: 400;
    --font_medium: 500;
    --font_semiBold: 600;
    --font_bold: 700;
    --button_color_gradientColor: linear-gradient(to right, rgb(96, 96, 182), #501078);
    --button_bg_hover: linear-gradient(to right, #501078, blue);
    --text_color_admin: blue;
    --text_color: #495a69;
    --input_margin_bottom: 30px;
    --background_color: #f6f6f6;
    --nav_bg_color: #f6f6f6;
    --box_shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    --color-loader: #501078;
    --loader-size: 100px;
    --loader-speed: 500ms;
    box-shadow: 3px 3px 5px rgba(56, 56, 56, 0.16);
}

* {
    font-family: "Overpass", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

a {
    text-decoration: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input -webkit::-webkit-scrollbar {
    width: 0 !important;
}

select:focus {
    outline: none;
}

input[type=number]:focus,
textarea:focus {
    outline: none;
}

.form-check-input:checked {
    background-color: blue !important;
    border-color: blue !important;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar:horizontal {
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(214, 214, 214);
    border-radius: 5px;
}

.welcome {
    color: #501078;
    display: grid;
    margin: 16rem;
    place-items: center;
}

.welcome h4 {
    margin-bottom: 0;
}

/* Sign Page Start */
.sign_main_section {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-content: center;
    background-image: url(/public/bgImg/blue1.png);
}

.sign_box {
    width: 70vw;
    /* border: 1px solid #707070; */
    background-color: var(--background_color);
    box-shadow: var(--box_shadow);
    overflow: hidden;
}

.head_img_box {
    width: 100%;
    height: 100%;
    background-image: url(/public/bgImg/stockNex.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: grid;
    place-content: center;
    position: relative;
}

.signin_heading {
    display: grid;
    place-content: center;
    font-weight: 700;
    color: blue;
    margin-bottom: 25px;
}

.password_div {
    position: relative;
}

.password_icon i {
    position: absolute;
    top: 55%;
    left: 82%;
    transform: translate(-50%, -50%);
}

.password_icon_div {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
}

.head_img_box::before {
    position: absolute;
    content: "";
    /* background-color: rgba(0, 0, 0, 0.603); */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.head_img_box h3 {
    color: #fff;
    font-size: 3rem;
    z-index: 1;
}

.sign_input_main {
    display: flex;
    align-items: center;
}

.sign_input_box {
    display: grid;
    place-items: center;
    padding: 15% 6% 15% 4%;
}

.from_div {
    width: 100%;
}


/* .label {
    color: #000;
    line-height: 3;
    margin-left: 40px;
} */

.label_div label {
    color: #495a69;
    font-size: 18px;
}

.input_div input {
    border: 0;
    border-bottom: 1px solid #495a69;
    outline: none;
    border-radius: 0;
    background-color: transparent;
}

.input_div input::placeholder {
    color: #afb7be;
}

.input_div input:focus {
    outline: none;
    border-bottom: 1px solid #501078;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 0.9rem;
}

.sign_input_box .input_div {
    text-align: center;
}

.input_div button {
    background: var(--button_color_gradientColor);
    font-size: 15px;
    color: #fff;
    border: none;
    padding: 0.1875rem 0.9375rem;
    padding: 3px 30px !important;
    border-radius: 5px;
}

.input_div button:hover {
    background: var(--button_bg_hover);
}

.sign_input_box .input_div button {
    min-width: 80px;
    min-height: 30px;
}

/* 
.btn_search_section button :hover{
    background: var(--button_bg_hover);
}

.create_button .btn_search_section button:hover{
    background:var(--button_bg_hover);
} */



/* Sign Page End */

/* Side Navbar Start */
.dashboard_main {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.side_navbar_panel {
    width: 20%;
}

.side_navbar_main {
    background-color: white;
    width: 100%;
    height: 100vh;
}

.logo_main {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_main img {
    width: 55%;
    height: 100%;
}

.side_navbar_tab {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    overflow: auto;
    height: 80%;
}

.side_navbar_main .side_navbar_tab .active li {
    /* background-color: var(--text_color_admin) !important; */
    background-color: blue !important;
    color: #fff !important;
}

.side_navbar_tab::-webkit-scrollbar {
    width: 0;
}

.side_navbar_main .side_navbar_tab li {
    background-color: #fff;
    color: var(--text_color);
    padding: 0.3125rem 0.625rem;
    font-size: var(--side_nav_font);
    font-weight: var(--font_semiBold);
    cursor: pointer;
    position: relative;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    display: flex;
}

/* .side_navbar_tab .active li {
    background-color: var(--text_color_admin);
    color: #fff;
} */

/* .side_navbar_tab .active li::after {
    opacity: 1;
} */

.side_navbar_tab a li:hover {
    color: var(--text_color_admin);
    background-color: var(--nav_bg_color);
}

/* .side_navbar_tab li:hover::after {
    opacity: 1;
} */

.side_navbar_tab li .tab_icon {
    margin: 0 1rem 0px 1rem;
    width: 1.25rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    position: relative;
}


.notification-badge {
    background-color: #fff82b;
    padding: 3px 5px;
    color: white;
    border-radius: 50%;
    font-family: var(--font_medium);
    font-weight: 600;
    font-size: 10px;
    margin-left: 2px;
    animation: blink 1.5s infinite ease;
}

@keyframes blink {
    0% {
        background-color: blue;
        color: white;
    }

    50% {
        background-color: #fff82b;
        color: black;
    }

    100% {
        background-color: blue;
        columns: white;
    }
}

/* Side Navbar End */

/* Right Panel Start */
.right_side_panel {
    width: 80%;
    height: 100vh;
    background-color: #f6f6f6;
}

.header_main {
    /* width:1100px; */
    /* height: 7vh; */
    background-color: white;
}

.user_name_text {
    display: grid;
    place-content: center;
    padding: 1vh;
}

.user_name_text h4 {
    color: blue;
    font-size: 18px;
    font-weight: var(--font_bold);
}

.logout_icon {
    display: grid;
    place-content: center;
    padding: 1vh;
}

.logout_icon .fa-power-off {
    font-size: 24px;
    color: blue;
    cursor: pointer;
}

.header_main h4 {
    margin: 0;
}

/* Right Panel End */

/*Side-Menu Dropdown  start */
.dropdown {
    margin: 0 0 0 1rem;
    cursor: pointer;
}

/*Side-Menu Dropdown  end */
/* create/update header */

.right_section {
    /* background-color: var(--nav_bg_color); */
    padding: 15px;
    /* background-image: url('/public/bgImg/bg_color.jpg'); */
}

.notification_section {
    padding: 15px;
}

.companySummary_section {
    padding: 15px;
}

.search_header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.title {
    font-size: var(--side_nav_font);
    font-weight: var(--font_bold);
    color: var(--text_color);
}

.search {
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    margin: 0px 50px;
}

.search-input {
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(56, 56, 56, 0.16);
    border: none;
    width: 18rem;
}

.search-input:focus {
    outline: none;
}

.search-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: blue;
}

.btn_search_section {
    background-image: linear-gradient(to right, blue, #501078);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 3px 10px;
}

/* Loader CSS Start */
.loader_main {
    width: 100%;
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader_parent {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}

.loader {
    --loader-height: calc(var(--loader-size) / 9);
    display: flex;
    position: relative;
}

.loader::before {
    content: "";
    position: absolute;
}

.loader::before,
.loader>span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);

    animation-name: to-right;
    animation-duration: var(--loader-speed);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.loader>span:nth-child(even) {
    visibility: hidden;
}

.loader>span:first-child {
    animation-name: scale-up;
}

.loader>span:last-child {
    animation-name: scale-up;
    animation-direction: reverse;
}

@keyframes to-right {
    to {
        transform: translateX(200%);
    }
}

@keyframes scale-up {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

/* Loader CSS End */

.data_setion {
    height: 82vh;
    background-color: var(--background_color);
    width: 100%;
    overflow: scroll;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    /* background-image: url('/public/bgImg/bg_color.jpg'); */

}

/*Create Company  start */

.table_main_access {
    max-height: 300px;
    overflow-y: auto;
}

.table_main_access .table_header h5 {
    font-size: var(--primary_font_size);
    display: grid;
    place-items: center;
    margin-bottom: 0;
}

.table_main h5 {
    margin-bottom: 0;
    /* color: #fff; */
    font-size: var(--table_header_font)
}

.table_main h6 {
    margin-bottom: 0;
    color: var(--text_color);
    font-size: var(--table_data_font);
    text-transform: capitalize;
}

.user_management_table .table_data .user_id h6 {
    text-transform: unset;
}

.table_header {
    /* width: 100%;
    overflow: auto; */
    z-index: 1;
}

.table_header div {
    display: grid;
    place-items: center;
}

.table_header,
.table_data {
    background-color: blue;
    color: #fff;
    display: flex;
    padding: 8px 0px;
    position: sticky !important;
    top: 0;

}

.table_data {
    z-index: 0;
}

.table_data {
    background-color: #fff;
    box-shadow: 0 4px 8px 0px rgb(0 0 0 / 30%);
    ;
    /* border-right: 1px solid #fff; */
}

.table_data_gap {
    margin-bottom: 2.5px;
}

.data_not_available {
    text-align: center;
    margin-top: 4rem;
    font-size: 1rem;
}

.data_not_available_transt {
    margin: 12rem
}

.data_not_available_for_longdiv {
    margin-left: 28rem;
    margin-top: 4rem;
    font-size: 1rem;
}

.icon_disable {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.icon_disable .icon_color {
    margin-right: 0;
}

/* table heading data start */
.srno {
    width: 120px;
    text-align: center;
    border-right: 1px solid #fff;
}

.message {
    width: 600px;
    text-align: center;
    border-right: 1px solid #fff;
}

.table_data .table_data_border {
    border-right: 1px solid #000;
}

.icon_color {
    color: blue;
    margin-right: 1rem;
}

.icon_pen {
    margin-right: 0px !important;
}

.icon_position {
    margin-right: 0rem;
}

/*Company table heading data start */

/*  table heading data end */

/* create screen */
.parent_section {
    display: grid;
    place-items: center;
    height: 93vh;
    /* background-image: url('/public/bgImg/bg_color.jpg'); */
}

.parent_section_access {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93vh;
}

.create_main {
    width: 60%;
    max-height: 87vh;
    /* overflow-y: auto;
    overflow-x: hidden; */
    color: var(--text_color);
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    /* display: block; */
    position: relative;

}

/* .create_main {
    width: 60%;
    height: auto;
    color: var(--text_color);
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    
    position: relative
} */

.form_create {
    position: absolute;
}

.title_create h6 {
    font-size: 16px;
    text-align: center;
    font-weight: var(--font_bold);
    color: var(--text_color);
    padding-top: 3%;
    text-transform: capitalize;


}

.from_row {
    margin-top: 30px;
    margin-bottom: 5px;
    /* align-items: center; */
}

.from_row button {
    margin-top: 30px;
    margin-bottom: 20px;
}

.form_data {
    color: #ebeef5;
    font-size: 12px;
    margin-bottom: 20px;
}

.margin_class {
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: baseline;
    padding-top: 10px
}

.form_data input {
    width: 85%;
}

.create_button {
    float: right;
    margin-bottom: 50px;
}

/* .row_margin {
   margin-bottom: -25px;
} */

.row_view {
    margin-right: 30px;
}

.create_main .form_data .form-select {
    width: 85% !important;
    background-color: none;

}

.select_grp .viewtransaction :hover {

    background-color: blue
}

/* .form_data .select_grp {
    width: 85%;
} */



.form-select .select_grp {
    width: 55%;
    border: 1px solid blue;
}

/* Ledger table heading data start */
.srno {
    width: 120px;
    text-align: center;
    border-right: 1px solid;
}

.table_data.srno_section {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.table_header.srno_section {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.companyName {
    width: 400px;
    text-align: center;
    border-right: 1px solid;
}


.creationDate {
    width: 220px;
    text-align: center;
    border-right: 1px solid;
}

.status {
    width: 300px;
    text-align: center;
    border-right: 1px solid;

}

.table_header .summary,
.table_data .summary {
    width: 500px;
    text-align: center;
    border-right: 1px solid #fff;
}

.table_data .summary {
    border-right: 1px solid #000;
}

.table_header .summary:last-child,
.table_data .summary:last-child {
    border-right: unset;
}

.status_section {
    width: 300px;
    text-align: center;
    border-right: 1px solid;
}

/* .action {
    width: 220px;
    display: flex;
    justify-content: space-around;
    align-items: center;
} */
.table_header .action_section {
    width: 300px;
    text-align: center;
    /* border-right: 1px solid; */
}

.table_data .action_section {
    width: 300px;
    text-align: center;
    /* border-right: 1px solid; */
}

.table_header .account_section {
    width: 500px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-right: 1px solid;
}

.table_data .account_section {
    width: 500px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.table_data_main {
    height: 75vh;
    /* overflow: auto; */
}

.site_table_view {
    height: 75vh;
    overflow: auto;
}

.table_data_mistake {
    /* height: 75vh; */
    overflow: auto;
}

/* create screen */
/* .create_main {
    width: 100%;
    color: --text_color;
    background-color: #fff;
    box-shadow: 3px 3px 5px #000;
} */

.groupName {
    width: 300px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .action {
    width: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    color: var(--text_color);
}

.table_header .action {
    width: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_header .groupName {
    width: 330px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .groupName {
    width: 330px;
    text-align: center;
    border-right: 1px solid;
}

.table_header .companyName_section {
    width: 550px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .companyName_section {
    width: 550px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .modeName {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.table_header .modeName {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.ledgerName {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.ledgerName_section {
    width: 500px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .ledgerName_section {
    width: 500px;
    text-align: center;
    border-right: 1px solid;
}

.table_header .ledgerName_section {
    width: 500px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .subLedgerName {
    width: 600px;
    text-align: center;
    border-right: 1px solid;
}

.table_header .subLedgerName {
    width: 600px;
    text-align: center;
    border-right: 1px solid;
}

.credit {
    width: 270px;
    text-align: center;
    border-right: 1px solid;
}


.subLedgerName_data {
    width: 255px;
    text-align: center;
    border-right: 1px solid #000;
}

.creationDate {
    width: 220px;
    text-align: center;
    border-right: 1px solid;
}

/* .action {
    width: 220px !important;
    text-align:center;
} */

.sitename {
    width: 320px;
    text-align: center;
    border-right: 1px solid;
}

.place {
    width: 300px;
    text-align: center;
    border-right: 1px solid;
}

.ownerName {
    width: 280px;
    text-align: center;
    border-right: 1px solid;
}

.ptcontractor {
    width: 420px;
    text-align: center;
    border-right: 1px solid;
}

.contractor {
    width: 410px;
    text-align: center;
    border-right: 1px solid;
}

.fix_amt {
    width: 270px;
    text-align: center;
    border-right: 1px solid;
}

.material_name {
    width: 300px;
    text-align: center;
    border-right: 1px solid;
}

.user_name {
    width: 220px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .bankName {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.table_header .bankName {
    width: 350px;
    text-align: center;
    border-right: 1px solid;
}

.user_id {
    width: 220px;
    text-align: center;
    border-right: 1px solid;
}

.password {
    width: 220px;
    text-align: center;
    border-right: 1px solid;
    position: relative;
}

.table_data .password h6 {

    text-transform: none;
}


.password i {
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
}

.balance_amt {
    width: 420px;
    text-align: center;
    border-right: 1px solid;
}

.balance_amt_section {
    width: 450px;
    text-align: center;
    border-right: 1px solid;
}

.opening_balance {
    width: 330px;
    text-align: center;
    border-right: 1px solid;
}

.read {
    /* text-align: center; */
    display: block;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.write {
    text-align: left;
    margin: 0px 3px 0px;
}

.role {
    width: 220px;
    text-align: center;
    border-right: 1px solid;
}

.table_header .access {
    width: 400px;
    text-align: center;
    border-right: 1px solid;
}

.table_data .access {
    width: 400px;
    text-align: left;
    border-right: 1px solid;
    color: var(--text_color);
}

.table_data .access h6 ul li {
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;
}

.check_access {
    /* padding-top: 10px; */
    display: flex;
    align-items: center;
}

.access_table {
    margin: 5px 10px 10px;
}

/* 
.access_tab {
    text-align: left;
} */

.table_data .srno,
.table_data .groupName,
.table_data .ledgerName,
.table_data .subLedgerName,
.table_data .creationDate,
.table_data .sitename,
.table_data .place,
.table_data .ownerName,
.table_data .ptcontractor,
.table_data .companyName,
.table_data .contractor,
.table_data .fix_amt,
.table_data .material_name,
.table_data .user_name,
.table_data .user_id,
.table_data .password,
.table_data .role,
.table_data .voucher,
.table_data .group,
.table_data .ledger,
.table_data .narration,
.table_data .credit,
.table_data .debit,
.table_data .date,
.table_data .access,
.table_data .status,
.table_data .balance_amt,
.table_data .opening_balance,
.table_data .srno_section,
.table_data .companyName_section,
.table_data .voucher_section,
.table_data .date_section,
.table_data .group_section,
.table_data .group_section,
.table_data .ledgerName_section,
.table_data .ledgerName_section,
.table_data .subledger,
.table_data .balance_amt_section,
.table_data .credit_section,
.table_data .debit_section,
.table_data .narration_section,
.table_data .status_section,
/* .table_data .action_section, */
.table_data .modeName,
.table_data .bankName,
.table_data .sr_view,
.table_data .account_section,
.table_data .message {

    border-right: 1px solid var(--text_color);
    color: var(--text_color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* table heading data end */

/*-----validation error-----*/
.srv-validation-message {
    color: rgb(173, 9, 9);
    margin-top: 8px;
    text-align: left;
    font-size: var(--primary_font_size);
}

.checkbox_validation_msg .srv-validation-message {
    text-align: right;
    margin-right: 20%;
}

.table_data .text_decorator {
    border-bottom: 1px dotted var(--text_color_admin);
    cursor: pointer;
}

.hide_action {
    color: var(--text_color);
}

/* site transaction start  */

.site_details {
    width: 105%;
    height: 79vh;
    background-color: #fff;
    box-shadow: var(--box_shadow);
    font-size: 14px;
    padding: 20px;
    margin-right: 20px;
    overflow: auto;
}

.contract-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.make_payment_button {
    text-align: center;
    padding-top: 10px;
}

.pay_amount_wrapper .srv-validation-message {
    text-align: center;
}

.input_control {
    display: block;
    width: 62%;
    /* height: 33px; */
    margin: 25px 0px 15px 65px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid blue;
    -webkit-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.box_control {
    width: 100%;
    /* margin-left: -36px; */
    /* border:  solid; */
    display: block;
    margin-bottom: 10px;
    padding: 0.275rem 0.25rem 0.375rem 0.75rem;
    font-size: 1;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 10px;
    color: #212529;
    background-color: #fff;
}

.button_view {
    /* margin-left: 29%; */
    height: 30px;
    /* margin-top: 8%; */
}

.button_margin {
    margin-right: 25px;
}

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
}

.radio_view {
    margin: 14px;
}

.site_transaction_table {
    height: 79vh;
}

.site_transaction_section {
    height: 1100px;
    overflow: auto;


}

.transaction_mistake_scroll {
    height: 1300px;
    overflow: auto;

}

.transaction_mistake_scroll .table_main {
    min-width: 1800px;
    /* overflow: auto; */

}


.site_transaction_table .table_main {
    width: 1100px;
    overflow: auto;
}

.company_summary_table {
    position: relative;
}

.site_management_table .table_main,
.contractor_management_table .table_main,
.site_mistake_table .table_main {
    min-width: 1400px;
}

.company_summary_table .table_main {
    min-width: 2300px;
}

/* 
.site_transaction_table .table_header .creationDate,
.site_transaction_table .table_data .creationDate {
    border-right: none !important;
} */
.total_amount_wrapper {
    position: absolute;
    right: 1.4%;
    bottom: 0.4%;
    display: flex;
    align-items: center;
    gap: 15px;
}

.total_amount_wrapper h5 {
    margin-bottom: 0;
    font-size: 17px;
    color: var(--text_color_admin);
}

.total_amount_wrapper .amount {
    border: 1px solid var(--text_color_admin);
    min-width: 80px;
    text-align: center;
    display: grid;
    place-items: center;
    background-color: #fff;
    padding: 0 10px;
    color: var(--text_color_admin);
}

.table_main::-webkit-scrollbar {
    display: block;
    width: 5px;
}

.table_main::-webkit-scrollbar-track {
    background: transparent;
}

.table_main::-webkit-slider-thumb {
    background-color: #501078;
    border-right: none;
    border-left: none;
}

.table_main::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
}

.table_main::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}



.pay {
    margin-left: 3%;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.makeTransaction {
    margin-left: 2rem;


}

.transaction_section_headingtable {
    position: sticky;
}

.search_section {
    top: 65px;
    right: 19%;
    color: #fff;
    text-align: center;
    padding-top: 5px;
    background-image: linear-gradient(to right, blue, #501078);
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.icon_pos {
    margin-right: 0rem;
}

.site_transaction_view {
    margin: 8px;
    border: 1px solid blue;
    height: 30px;
}

input[type="date"]::before {
    color: #999999;
    content: attr(placeholder);
}

input[type="date"] {
    color: #ffffff;
}

.site_transaction input[type="date"] {
    padding: 5px;
    border: 1px solid rgb(173, 173, 173);
    font-size: 1rem;
}

/* input[type=text]:focus {
    border: 1px solid blue;
} */

.date_view {
    width: 20%;
    padding: 6px;
}

/* site transaction end  */


/* ViewTransactionsection start*/
.viewtransaction {
    margin: 8px 8px;
    border: 1px solid blue;
    height: 31px;
    width: 150px;
    padding: 2px
}


input[type=date]::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    /* display: none; */
}

input[type=date]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    /* display: none; */
}

input[type=date]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    /* display: none; */
}

input[type=date]::-webkit-datetime-edit {
    -webkit-appearance: none;
    /* display: none; */

}

input[type=date],
input,
select,
textarea {
    border-radius: 3px;
}

.showIcon {
    /* background-image: url('/public/bgImg/cale.png');
    background-repeat:no-repeat; */
    width: 15px;
    position: absolute;
    right: 12%;
    bottom: 112px;


}

.showIcon img {
    width: 10px;
}

.transaction_data {
    height: 70vh;
    background-color: var(--background_color);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.view {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: auto;
}

.view::-webkit-scrollbar {
    height: 0;
}

.sr_view {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
    text-align: center;
}


.viewtransactionsection_view {
    margin: 8px;
    border: 1px solid blue;
    height: 30px;
}

.viewtransactionsection_table {
    height: 70vh;
    margin-left: 1px;
}

.data_setion .viewtransactionsection_table .table_main .table_header {
    display: flex;
    position: sticky !important;
}

.transactionsection_table .table_main {
    min-width: 1500px;
    overflow: scroll;
}

/* .viewtransactionsection_table  .table_main  .table_data_main
.table_header{
    position: sticky;
    display: flex;
} */

.viewtransactionsection_table .table_main .table_data {
    width: 2000px;
}

.table_data .debit {
    color: var(--text_color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.viewtransactionsection_table .table_main {
    width: 2000px;
    /* overflow: scroll; */
}

.transactionsection_search {
    top: -37px;
    left: 106%;
    color: #fff;
    text-align: center;
    padding-top: 5px;
    background-image: linear-gradient(to right, blue, #501078);
    width: 30px;
    height: 30px;
    border-radius: 5px;

}

/* .transactionsection_search_icon{
    top: -40px;
    right: 4%;
    color: #fff;
    text-align: center;
    padding-top: 5px;
    background-image: linear-gradient(to right, blue, #501078);
    width: 30px;
    height: 30px;
    border-radius: 5px;
}  */
.transactionsection_search_icon i {
    position: fixed;
}

.transactionsection_search i {
    position: fixed;
}

.transactionsection_date {
    width: 17%;
    padding: 7px;
    margin: 8px;
    border: 1px solid blue;
    height: 30px;

}

.transactionsection_date placeholder {
    color: #000;
}

.transactionsection_table {
    padding-top: 26px;
}

.date {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.table_header .date_section {
    width: 530px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.table_data .date_section {
    width: 530px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.voucher {
    width: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_data .voucher_section {
    width: 360px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_header .voucher_section {
    width: 360px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}


.group {
    width: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}


.table_header .sr_view {
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_data .sr_view {
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.table_data .group_section {
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
    text-align: center;
}

.table_header .group_section {
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_data .ledger {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_header .ledger {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_data .subledger {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_header .subledger {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.narration {
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.remark {
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
}

.table_header .narration_section {
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.table_data .narration_section {
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
    text-align: center;
}

.credit {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.table_header .credit_section {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.table_data .credit_section {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
}

.debit {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_header .debit_section {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;

}

.table_data .debit_section {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;

}

.transactionsection_nav {
    position: sticky;
}


/*Make Transaction start */

.maketransaction_top {
    margin-top: 20px;
}

.maketransaction_text {
    margin-left: 1px;
    font-weight: bold;
}

.maketransaction_lable {
    font-weight: bold;
}

.transaction_text {
    color: var(--text_color_admin);
    font-weight: var(--font_bold);
}

/* 15px */
.maketransaction_box {
    width: 100%;
    /* margin: 3px 26px 0px 28px; */
    border: 1px solid blue;
    height: 30px;
    padding: 0 0 0 5px;
    /* float: left; */
}

.maketransaction_box:focus {
    outline: none;
}

.maketransaction_ {
    width: 70%;
    /* margin: 3px 26px 0px 28px; */
    border: 1px solid blue;
    height: 30px;
    /* float: left; */
}

.update_transaction_form .maketransaction_box {
    width: 100%;
}

.update_transaction_form .react-datepicker__input-container input {
    border: 1px solid blue;
    border-radius: 3px;
}

.transaction_textarea {
    height: 60px;
    width: 60%;
}

.total {
    /* width: auto; */
    display: flex;
}

.total input {
    margin-left: 13px;
    padding: 0 0 0 5px;
}

.total_transation {
    padding-left: 5px;
}


.total input {
    margin-left: 10px;
}

.transaction_btn {
    float: left;
}

.balance_section_cb {
    float: right;
}

.maketransaction_date {
    padding: 8px;
}

.maketransaction_date_text {
    margin: 10px 30px 0px 27px;
}

.maketransaction_table {
    height: 89vh;
    background-color: var(--background_color);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.maketransaction_vaucher {
    margin: -25px 0px -2px 0px;
    margin-left: 26%;
}

.maketransaction_balance {
    margin: 8px 0px 0px 243px;
    padding: 13px;
}

.maketransaction_heading {
    margin: 55px 0px 0px 29px;
}

.maketransaction_view {
    margin-left: 122%;
    text-align: right;
}

.maketransaction_heading h6 {
    color: blue;
    /* margin-top: -3%; */
    text-transform: capitalize;
}

.maketransaction_group {
    margin-top: 15px;
}

.maketransaction_debit {
    margin: -1% 0% 0% 51%;
}

.maketransaction_size {
    font-size: 16px;
}

.maketransaction_input {
    font-size: 14px;
}

input[type="date"] {
    padding: 0 5px;
}

input[type="date"]:focus,
input[type="text"]:focus,
input[type="date"]:valid {
    color: #501078;
    outline: none;
}

.box {
    margin-left: -17%;
}

.text {
    margin-left: -16%;
}

.ledgertext {
    margin: 4% 0% 2% -15%;
}

.subledgertext {
    margin: -2% 0% 1% -15%;
}

.narrationtext {
    margin: 2px 0px 0px 28px;
}

.mybtn {
    margin: 5% 0% 0% 5%;
    padding: 5% 0% 0% 5%;
}

.btn-view {
    margin-right: 29px;
}

.wrapper {
    display: grid;
    grid-template-columns: 360px 360px 360px
}

.debit_text {
    text-align: right;
    margin-right: 30%;
}

.debit_input {
    border: 1px solid blue;
    margin: 2% 0% 0% 27%;
    width: 57%;
    height: 29px;
    padding: 13px;
}



/* for date picker */
.form_data .react-datepicker__input-container input {
    display: block;
    width: 85%;
}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.50rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.datepicker_input {
    width: 100% !important;
}

.example-custom-input {
    background: url("/public/bgImg/calender.png") no-repeat right;
    background-size: 25px;
    color: #501078;
    padding: 10px;
    /* width: 350px; */
    height: 35px;
}

.example-custom-input:focus-visible {

    outline: none;
}




.react-datepicker__navigation-icon {
    position: relative;
    top: 15px;
    font-size: 20px;
    width: 0;
    /* color: #000; */
}


.react-datepicker__day-names {
    margin-bottom: 0px;
}

.date_view input {
    width: 85%;

}

/* toggle Switch */
.form-check .form-switch .form-check-input {
    background-color: #501078;
    cursor: pointer !important;
}

.toggle_text {
    color: #000;
    font-size: 12px;

}

/* access tab  */
.create_main_screen {
    width: 98%;
    height: 88vh;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.parent_section_access .tab_access .lable_gap {
    font-size: 15px;
}

.parent_section_access .table_main_access h5,
.parent_section_access .table_main_access h6 {
    font-size: 14px;
}

.create_main_screen::-webkit-scrollbar {
    width: 0;
}

.lable_gap {
    margin-left: 5px;
}

.form-switch {
    padding-left: 0;
}

.form-check {
    display: block;
    min-height: 0.5rem;
    padding-left: 1.5em;
    /* margin-bottom: -0.50rem !important; */
}

.icon_view {
    margin: 0px 30px 0px 0px;
}

/* change color in mistake */
.mistake_text_color {
    background-color: #f0f0f3;


}

.transaction_lable {
    float: right;

}

/* backup section css */

.backup_section {
    height: 89vh;
    background-color: var(--background_color);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.backup_view {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 40%;
}

.backup_btn {
    width: 113px;
}

.backup_text {
    color: blue;
}

.backup_icon {
    position: relative;
    left: 50px;
    top: 28px;
    color: blue;
    font-size: 22px;
}

.backup_pos {
    top: 2px;
}



/* Report section  css */

.report_section .active button {
    background-color: blue;
    color: #FFF;
}

.report_btn {
    color: #000;
    border: 1px solid blue;
    padding: 3px 10px;
    width: 100%;
}

/* .report_btn:hover {
    background-image: linear-gradient(to right, blue, blue);
    color: #fff;
} */

.report_view {
    margin: 0 10px;
}

.report_view .heading {
    margin-top: 10px;
}

.report_input {
    margin: 8px 4px;
    border: 1px solid blue;
    height: 30px;
    width: 100%;
}

.total_amount_section {
    width: 100%;
    margin: auto;
}

.btn_pos {
    margin-top: 7px;
}

.box_reoprt {
    border: 1px solid gray;
}

.lab_col {
    border-right: 1px solid grey;
}

.balance_row {
    border-bottom: 1px solid grey;

}

.title_icon_wrapper span {
    margin-left: auto;
}

.title_icon_wrapper span i {
    cursor: pointer;
}

.inner_card_data .report_innerdiv {
    margin: 8px 0;
    color: darkgray;
}

.report_innerdiv .col-6:nth-child(1) {
    padding-left: 15px !important;
}

.inner_card_data .report_innerdiv h6 {
    margin-bottom: 0px !important;
    font-size: 13px;
}

.amt_sheet {
    float: right;
    font-weight: 700;
    position: relative;
    bottom: 1.5rem;
}

.profit_loss_total {
    position: relative;
    min-height: 7vh;
    margin: 10px 0px;
}

.profit_loss_total span {
    padding: 3px 0px 0 20px;
    position: absolute;
    right: 0;
    border-top: 1px solid gray;
    border-bottom: 1px solid grey;
    font-size: 13px;
    font-weight: 700;
}

.icon_div {
    position: relative;
    bottom: 29px;
    left: 135px;
}

.icon_div_pl {
    position: relative;
    bottom: 29px;
    left: 160px;
    cursor: pointer;
}

.main_card .heading_class,
.main_card .amt_sheet,
.inner_card_data h6 {
    font-size: 13px;
}

.heading_class {
    font-weight: 700;
}

.inner_card_data {
    position: relative;
    padding-left: 10%;
    bottom: 4%
}

.total_name {
    display: flex;
    /* position: relative;
    top: 50%; */
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
}

.sum_text {
    /* position: relative;
    left: 15rem; */
    font-weight: 700;
}

.sum_amt {
    /* position: relative;
    left: 24.5rem; */
}

.bottom_border {
    border-bottom: 2px solid grey;
}


.border_class {
    border: 1px solid grey;
}

.row_div {
    display: flex;
}

/* Create two equal columns that sits next to each other */
.column_div {
    flex: 50%;
    padding: 10px;
}

/* Transaction Section  css*/
/* .tablemain{
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
} */

/*==========css for pagination=========*/
.pagination_main {
    margin: auto;
    overflow-x: scroll;
    /* position: absolute;
    bottom: -3%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-items: center;
    padding: 5px 0;
    background-color: #fff; */
}

.pagination_main::-webkit-scrollbar {
    width: 0;
}

.pagination {
    margin-bottom: 0;
}

.pagination li {
    padding: 4px 8px 0px 8px;
    color: var(--text_color_admin);
    border: 0.5px solid var(--text_color_admin);
    margin: 0 3px;
    border-radius: 3px;
    font-size: 13px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transition: .5s;
}

.pagination .previous,
.pagination .next {
    border: none;
}

.pagination li:hover {
    background-color: var(--text_color_admin);
    color: #fff;
}

.pagination li:hover a {
    color: #fff;
}

.pagination li a {
    color: var(--text_color_admin);
}

.pagination .active {
    background-color: var(--text_color_admin);
}

.pagination .active a {
    color: #fff !important;
}

.react-datepicker-popper {
    z-index: 2;
}

.graph_header_wrapper {
    width: 99%;
    overflow-x: auto;
}

.graph_header_wrapper::-webkit-scrollbar {
    height: 0px;
}

.graph_sheet_header {
    width: 100vw;
    display: flex;
    align-items: center;
    gap: 15px;
}

.graph_sheet_header .child {
    min-width: 200px;
    max-width: 250px;
}

.graph_sheet_header button {
    margin-top: 0;
}

.graph_sheet_header .child:focus {
    outline: none;
}

/*-----css for material sheet graph*/
.recharts-responsive-container {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
}

.recharts-wrapper {
    overflow-x: scroll;
}

.grpah_section {
    width: 100%;
    /* display: grid;
    place-items: center; */
    /* padding: 15px 0; */
    overflow-x: scroll;
}

.bar_graph_main::-webkit-scrollbar {
    height: 5px;
}

.bar_graph_main::-webkit-scrollbar-track {
    background-color: rgb(173, 173, 173);
}

.bar_graph_main::-webkit-scrollbar-thumb:horizontal {
    /* background-color: var(--primary_color); */
    border-radius: 10px;
}

.bar_graph_main {
    width: 100%;
    overflow-x: scroll;
}

.grpah_section canvas {
    margin: 15px;
    cursor: pointer;
    max-height: 400px;
}

/* *******************pagination css ***************************  */
.paginationWrapper {
    display: flex;
    align-items: center;
    gap: .7rem;
    margin-left: 2rem;
}

.paginationWrapper .btn_pagination {
    color: var(--text_color_admin);
    padding: 1.5px 8px;
    border-radius: 3px;
    border: 1px solid var(--text_color_admin);
    background-color: #fff;
    transition: .3s;
}

.paginationWrapper .btn_pagination:disabled {
    background-color: rgb(235, 235, 235);
    cursor: not-allowed
}


.paginationInputWrapper {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 33%;
    height: 1.8rem;
    border: 1px solid var(--Primary_color);
    border-radius: 3px;
}

.paginationWrapper h6 {
    color: var(--text_color);
    font-weight: 500;
    margin: 0;
}

.paginationInputWrapper .form-control {
    height: 100%;
    border: 1px solid var(--text_color_admin);
    text-align: left;
    padding: .7rem;
    color: var(--text_color);
}

.paginationInputWrapper .find_btn {
    border: unset;
    background-color: var(--text_color_admin);
    padding: 0px 10px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    color: #fff;
    border-radius: 0 3px 3px 0;
}


.paginationInputWrapper .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.paginationInputWrapper .find_btn:hover {
    background-color: #7900c4;
    color: #fff
}

@media print {
    .data_setion {
        box-shadow: none !important;
    }

    .box_reoprt {
        width: 97% !important;
    }

    .total_amount_section {
        width: 100%;
        margin: auto !important;
        border: 1px solid grey;
        border-top: none;
    }

    .total_amount_section .col-6:nth-child(odd) {
        border-right: 1px solid grey;
    }

    .heading {
        margin-top: 10px;
    }

    .main_card .heading_class,
    .main_card .amt_sheet,
    .inner_card_data h6,
    .profit_loss_total span {
        font-size: 10px;
    }

    .amt_sheet {
        margin-top: 10px;
    }

    .icon_div_pl {
        display: none;
    }

    .recharts-wrapper {
        /* width: 100%; */
        /* height: 500px !important; */
        margin-top: 50%;
        transform: rotate(90deg) !important;
    }

    ::-webkit-scrollbar {
        width: 0;
    }

    .grpah_section {
        width: auto !important;
        height: auto;
        overflow: visible;
    }

    .bar_graph_main {
        width: auto !important;
        height: auto;
        overflow: visible;
        transform: rotate(90deg);
        margin-top: 25% !important;
    }

    .bar_graph_main div {
        min-width: auto !important;
        height: auto;
        overflow: visible;
    }

    .grpah_section canvas {
        height: auto !important;
        overflow: visible;
    }
}


/* Tablat Screen 1024px to 768 px */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .side_navbar_tab .tab_title {
        font-size: 13px;
    }

    .search_header {
        overflow-x: auto;
    }

    .search_header::-webkit-scrollbar {
        height: 0;
    }

    .material_management_search_header div:nth-child(3) {
        margin-right: 8px;
    }

    .search_header .title {
        font-size: 14px;
    }

    .srv-validation-message {
        font-size: 13px;
    }

    .view {
        margin-bottom: 5px;
    }

    .btn_search_section {
        font-size: 13px;
    }

    .search {
        font-size: 14px;
        padding: 2px 5px;
    }

    .report_view .btn_pos {
        margin-top: 13px;
    }

    .data_setion .table_main h5 {

        font-size: 14px;

    }

    .data_setion .table_main h6 {

        font-size: 12px;

    }

    .icon_color {
        font-size: 12px;
        padding-left: 5px;
    }

    .viewtransaction {
        margin: 1px 1px;
        border: 1px solid blue;
        height: 25px;
    }

    .transactionsection_search {
        top: -22px;
        left: 106%;
        color: #fff;
        text-align: center;
        padding-top: 5px;
        background-image: linear-gradient(to right, blue, #501078);
        width: 23px;
        height: 23px;
        border-radius: 5px;
    }

    .table_header {
        background-color: blue;
        color: #fff;
        display: flex;
        /* padding: 3px 0px; */
        position: sticky;
        top: 0;
    }

    .table_data {
        padding: 3px 0px;
    }

    .data_setion .table_main h5 {
        margin-bottom: 0;
        /* color: #fff; */
        font-size: 10px;
    }

    .data_setion .table_main h6 {
        margin-bottom: 0;
        /* color: #fff; */
        font-size: 9px;
    }

    .side_navbar_main .side_navbar_tab li {
        background-color: #fff;
        color: var(--text_color);
        padding: 0.3125rem 0.625rem;
        font-size: 12px;
        font-weight: var(--font_semiBold);
        cursor: pointer;
        position: relative;
        /* margin-top: 0.9375rem;
  margin-bottom: 0.9375rem; */
        display: flex;
    }

    .form-check {
        font-size: 12px;
        margin-top: 3px;
        margin-right: 8px;
    }

    .site_details {
        /* width: auto; */
        /* height: 79vh; */
        background-color: #fff;
        box-shadow: var(--box_shadow);
        font-size: 14px;
        padding: 5px;
        /* margin-right: 10px; */
    }

    .label {
        font-size: 10px;
    }

    .site_details .label {
        font-size: 13px;
    }

    .box_control {
        width: 100%;
        /* margin-left: -12px; */
        border: 1px solid blue;
        display: block;
        margin-bottom: 12px;
        padding: -0.725rem -1.75rem 2.375rem 0.75rem;
        font-size: 9px;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
    }

    .radio_view {
        margin: 11px;
    }

    .btn_search_section {
        font-size: 11px;
    }

    .logo_main {
        height: 13vh;
    }

    .password i {
        left: 87%;
    }

    .search {
        margin: 0 20px;
    }

    .create_main {
        width: 80%;
    }

    .create_main_screen {
        width: 98%;
    }

    .create_main_screen .label {
        font-size: 16px;
    }

    .recharts-responsive-container {
        width: 1200px !important;
    }

    .graph_sheet_header .child {
        min-width: unset;
        max-width: 250px !important;
    }
}

@media screen and (width:768px) {
    .dropdown {
        padding-left: 1rem;
    }

    .margin_class {
        margin-left: 15px;
    }

    .side_navbar_tab li .tab_icon {
        margin: 0 0.3rem 0px 0.3rem;
    }

    .search-input {
        width: 12rem;
    }

    .search {
        margin: 0 10px;

    }

    .pagination_main {
        margin-left: 5px;
    }

    .parent_section_access .tab_access {
        display: flex;
        align-items: center;
    }

    .parent_section_access .tab_access .lable_gap {
        font-size: 14px;
    }

    .parent_section_access .table_main_access h5,
    .parent_section_access .table_main_access h6 {
        font-size: 12px;
    }

    .material_management_search_header div {
        min-width: 200px
    }

    .material_management_search_header div:nth-child(3) {
        min-width: 110px;
    }
    .tab_title{
        font-size: 10px;
    }
}

@media only screen and (min-width: 1024px) {
    .logo_main {
        height: 20vh;

    }
}

@media only screen and (min-width:1500px) and (max-width:2560px) {
    .search_header {
        gap: 15px !important;
    }

    .pagination_main {
        margin: unset !important;
    }

    .grpah_section canvas {
        max-height: unset;
    }

    /*======Compnay tab===========*/
    .create_main {
        padding: 2% 3%;
    }

    .title_create {
        margin-bottom: 5%;
    }

    .company_table_wrapper .srno {
        width: 300px;
    }

    .company_table_wrapper .companyName {
        width: 600px;
    }

    .company_table_wrapper .creationDate {
        width: 400px;
    }

    .company_table_wrapper .status {
        width: 400px;
    }

    /*=============User management tab============*/
    .parent_section_access .tab_access .lable_gap {
        font-size: 18px;
    }

    .parent_section_access .table_main_access h5,
    .parent_section_access .table_main_access h6 {
        font-size: 17px;
    }

    .create_main .form_data .form-select {
        width: 45%;
    }

    .user_management_table .srno {
        width: 200px;
    }

    .user_management_table .user_name {
        width: 500px;
    }

    .user_management_table .creationDate {
        width: 350px;
    }

    .user_management_table .user_id {
        width: 350px;
    }

    .user_management_table .password {
        width: 300px;
    }

    /*==========Ledger tab==========*/
    .ledger_table_wrapper .srno {
        width: 190px;
    }

    .ledger_table_wrapper .ledgerName {
        width: 400px;
    }

    .ledger_table_wrapper .companyName {
        width: 450px;
    }

    .ledger_table_wrapper .groupName {
        width: 400px;
    }

    .ledger_table_wrapper .creationDate {
        width: 260px;
    }

    /*========material management tab===========*/
    .matrial_management_table_wrapper .srno {
        width: 170px;
    }

    .matrial_management_table_wrapper .material_name {
        width: 400px;
    }

    .matrial_management_table_wrapper .sitename {
        width: 450px;
    }

    .matrial_management_table_wrapper .companyName {
        width: 450px;
    }

    .matrial_management_table_wrapper .creationDate {
        width: 250px;
    }

    /*==========company mistake tab===========*/
    .company_mistake_table_wrapper .srno {
        width: 200px;
    }

    .company_mistake_table_wrapper .companyName {
        width: 550px;
    }

    /*=========user mistake tab=============*/
    .user_mistake_table_wrapper .user_name {
        width: 400px;
    }
}

@media screen and (min-width:2100px) and (max-width:2560px) {
    .site_transaction_table .table_main {
        width: auto;
    }
}


@media screen and (width:320px) {
    .dropdown {
        padding-left: 1rem;
    }

    .margin_class {
        margin-left: 15px;
    }

    .side_navbar_tab li .tab_icon {
        margin: 0 0 0px 0.3rem;
    }

    .search-input {
        width: 12rem;
    }

    .search {
        margin: 0 10px;

    }

    .pagination_main {
        margin-left: 5px;
    }

    .parent_section_access .tab_access {
        display: flex;
        align-items: center;
    }

    .parent_section_access .tab_access .lable_gap {
        font-size: 14px;
    }

    .parent_section_access .table_main_access h5,
    .parent_section_access .table_main_access h6 {
        font-size: 12px;
    }

    .material_management_search_header div {
        min-width: 200px
    }

    .material_management_search_header div:nth-child(3) {
        min-width: 110px;
    }
    .tab_title{
        font-size: 10px;
    }
}



